@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '../../assets/styles/global/variables';
@import '~bootstrap/scss/mixins';
@import '../../assets/styles/global/mixins';

.section--testimonials {
  &.section-paddings {
    padding-top: 3.625rem;

    @include media-breakpoint-down(lg) {
      padding-top: map-get($spacers, 5);
    }
  }

  .font-size-0 {
    font-size: 0;
  }

  &.testimonials--type-2 {
    @include media-breakpoint-down(md) {
      .ts__title-sm {
        margin-top: 0;
        text-transform: none !important;
        font-weight: $font-weight-base;
        font-size: 2rem;
        line-height: 2.375rem;

        > span {
          display: inline-block;
          max-width: 11.25rem;
        }
      }

      .splide__arrows {
        top: 0 !important;
      }
    }
  }

  .images-grid {
    $border-color: #8f9dac;

    position: relative;
    z-index: 2;

    &__wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin: 0 auto;
      min-height: 24.75rem;
      max-width: 62.5rem;

      @include media-breakpoint-down(xl) {
        min-height: 370px;
      }

      @include media-breakpoint-down(lg) {
        min-height: 17.5rem;
      }

      @include media-breakpoint-down(md) {
        min-height: 0;
      }
    }

    &__row {
      display: flex;
      margin-bottom: 1.5rem;
      padding-right: calc(12.5rem + 6.5%);
      width: 100%;
      gap: 1.5rem;

      @include media-breakpoint-down(lg) {
        margin-bottom: 1rem;
        padding-right: 10.375rem;
        gap: 1rem;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 0;
        margin-bottom: 1rem;
        padding-right: 0;
      }

      @include media-breakpoint-down(sm) {
        align-items: center;
        flex-direction: column;
        margin-bottom: 0.75rem;
        gap: 0.75rem;
      }

      &:last-child {
        margin-bottom: 6.25rem;
        padding-right: 0;
        padding-left: calc(12.5rem + 6.5%);

        @include media-breakpoint-down(lg) {
          margin-bottom: 4.5rem;
          padding-left: 10.375rem;
        }

        @include media-breakpoint-down(md) {
          margin-bottom: 0;
          padding-left: 0;
        }
      }
    }

    &__item {
      position: relative;
      flex: 1 1 50%;
      max-width: 50%;
      border-radius: 1.25rem;
      background-color: #ccdff4;
      aspect-ratio: 355/136;

      @include media-breakpoint-down(sm) {
        flex: auto;
        max-width: 14rem;
        width: 100%;
        border-radius: 0.75rem;
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 56.34%;
        height: 67.65%;
        transform: translate(-50%, -50%);
        object-fit: contain;

        @include media-breakpoint-down(sm) {
          width: 7.75rem;
          height: 3.625rem;
        }
      }
    }

    &__decor {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 81.2%;
      border: 1px solid $border-color;
      border-top-right-radius: 11.25rem;
      border-bottom-right-radius: 11.25rem;
      aspect-ratio: 812/366;

      @include media-breakpoint-down(lg) {
        width: 84%;
      }

      @include media-breakpoint-down(md) {
        position: relative;
        margin-top: 1.5rem;
        width: 100%;
        border: none;
        aspect-ratio: auto;
      }
    }

    &__round {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      width: 12.5rem;
      height: 12.5rem;
      border: 1px solid $border-color;
      border-radius: 50%;
      color: $purple;
      font-size: 2.5rem;
      line-height: 1.2;

      @include media-breakpoint-down(lg) {
        width: 9.375rem;
        height: 9.375rem;
        font-size: 1.875rem;
      }

      @include media-breakpoint-down(md) {
        position: relative;
        margin: 0 auto;
      }
    }

    &__spinner {
      position: absolute;
      top: -1.5rem;
      right: 1.5rem;
      z-index: 10;

      @include media-breakpoint-down(lg) {
        top: -1rem;
        right: 1rem;
      }
    }
  }

  .testimonials {
    &__content {
      margin: 0 auto 3.5rem;
      max-width: 51.875rem;

      @include media-breakpoint-down(lg) {
        max-width: 26rem;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 2rem;
      }

      h2,
      .h2 {
        @include media-breakpoint-up(sm) {

          > span {
            display: block;

            &:nth-child(even) {
              text-align: right;
            }
          }
        }

        @include media-breakpoint-down(sm) {
          font-size: 3rem;
          line-height: 3.6rem;

          strong {
            font-weight: $font-weight-base;
          }
        }
      }
    }

    &__title {
      //font-size: 3.2rem;
      margin: 55px 0 0 auto;
      max-width: 29.6875rem;

      &--small {
        @include media-breakpoint-up(lg) {
          font-size: 2.75rem !important;
        }
      }

      @include media-breakpoint-down(xxl) {
        max-width: 24rem;
        font-size: 3.2rem;
      }

      @include media-breakpoint-down(lg) {
        margin: 25px auto 0;
        max-width: 384px;
        font-size: 2.375rem;
      }

      @include media-breakpoint-down(md) {
        max-width: 335px;
        font-size: 2rem;
      }
    }

    &__decor {
      &-img {
        &-wrapper {
          display: inline-block;
          box-shadow: 10px 10px 28px rgba(0, 0, 0, 0.06);
          transition: all 1.2s ease;

          &.is-animated {
            opacity: 1 !important;
            transform: translate(0, 0) !important;
          }

          @include media-breakpoint-down(lg) {
            opacity: 1 !important;
            transform: translate(0, 0) !important;
          }

          &::after {
            position: absolute;
            color: $white;
            content: attr(data-text);
            text-transform: uppercase;
            white-space: nowrap;
            font-weight: 700;
            font-size: $font-size-base;
            font-family: $headings-font-family;
          }
        }

        &--type-1 {
          margin: 43px 0 0;
          max-width: 202px;
          max-height: 178px;

          &::after {
            bottom: 10px;
            left: 15px;
          }
        }

        &--type-2 {
          margin: 106px 0 0 14px;
          max-width: 263px;
          max-height: 210px;

          --bs-aspect-ratio: calc(9 / 11 * 100%);

          @include media-breakpoint-down(xl) {
            margin: 106px 0 0;
          }

          &::after {
            bottom: 10px;
            left: 15px;
          }
        }

        &--type-3 {
          margin-top: 62px;
          max-width: 200px;
          max-height: 194px;

          @include media-breakpoint-down(lg) {
            margin: 0;
          }

          &::after {
            right: 15px;
            bottom: 10px;
          }
        }

        &--type-4 {
          margin: -62px 0 0 17px;
          max-width: 123px;

          @include media-breakpoint-down(xl) {
            margin: -62px 0 0;
          }

          @include media-breakpoint-down(lg) {
            margin: 0 0 5px;
          }
        }

        &--type-5 {
          margin: 127px -8px 0 0;
          max-width: 200px;
          max-height: 192px;

          @include media-breakpoint-down(xl) {
            margin: 127px 0 0;
          }

          &::after {
            top: 10px;
            left: 15px;
          }
        }
      }
    }

    &-slider {
      margin: 62px 0 0;

      &:only-child {
        margin-top: 42px;
      }

      @include media-breakpoint-down(lg) {
        margin: 20px 0 0;

        &:only-child {
          margin-top: 0;
        }
      }

      .splide__track {
        overflow: visible;
      }

      &__title {
        margin-bottom: 3.25rem;

        @include media-breakpoint-down(lg) {
          margin-bottom: 1.5rem;
        }
      }

      .splide {
        &__pagination-bar {
          position: absolute;
          top: 50%;
          right: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: $black;
          transition: max-width 0.5s ease;
          transform: translateY(-50%);
        }

        &__pagination-progress {
          position: relative;
          left: -10px;
          display: flex;
          justify-content: flex-start;
          margin-top: 75px;
          margin-bottom: 43px;
          padding: 0;
          border-bottom: 1px solid #8a8a8a;
          font-size: 0;

          @include make-col-offset(-6);

          @include media-breakpoint-down(lg) {
            left: 0;
            margin: 40px 0;
          }
        }

        &__arrows {
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          width: 8.375rem;
          font-size: 0;

          @include media-breakpoint-down(lg) {
            position: relative;
            top: 12px;
            width: 4.5rem;
          }
        }

        &__arrow {
          position: relative;
          top: auto;
          right: auto;
          left: auto;
          width: 3.75rem;
          height: 3.75rem;
          border-radius: 0;
          background-color: transparent;
          background-image: url('data:image/svg+xml,%3Csvg width=\'60\' height=\'60\' viewBox=\'0 0 60 60\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M38.0287 30.9379C38.4713 30.4953 38.4713 29.7777 38.0287 29.3351L30.8162 22.1226C30.3736 21.68 29.656 21.68 29.2134 22.1226C28.7708 22.5652 28.7708 23.2828 29.2134 23.7254L35.6245 30.1365L29.2134 36.5476C28.7708 36.9902 28.7708 37.7078 29.2134 38.1503C29.656 38.5929 30.3736 38.5929 30.8162 38.1503L38.0287 30.9379ZM22.7727 31.2698L37.2273 31.2698L37.2273 29.0031L22.7727 29.0031L22.7727 31.2698Z\' fill=\'black\'/%3E%3Cpath d=\'M59.25 30C59.25 46.1543 46.1543 59.25 30 59.25C13.8457 59.25 0.75 46.1543 0.75 30C0.75 13.8457 13.8457 0.75 30 0.75C46.1543 0.75 59.25 13.8457 59.25 30Z\' stroke=\'black\' stroke-width=\'1.5\'/%3E%3C/svg%3E%0A');
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          opacity: 1;
          transition: opacity 0.3s ease-in;
          transform: none;

          @include media-breakpoint-down(lg) {
            width: 2rem;
            height: 2rem;
          }

          &[disabled] {
            &.splide__arrow--prev {
              background-image: url('data:image/svg+xml,%3Csvg width=\'60\' height=\'60\' viewBox=\'0 0 60 60\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M21.9713 29.0624C21.5287 29.505 21.5287 30.2226 21.9713 30.6652L29.1838 37.8776C29.6264 38.3202 30.344 38.3202 30.7866 37.8776C31.2292 37.4351 31.2292 36.7175 30.7866 36.2749L24.3755 29.8638L30.7866 23.4527C31.2292 23.0101 31.2292 22.2925 30.7866 21.8499C30.344 21.4073 29.6264 21.4073 29.1838 21.8499L21.9713 29.0624ZM37.2273 28.7304L22.7727 28.7304L22.7727 30.9971L37.2273 30.9971L37.2273 28.7304Z\' fill=\'%23516072\'/%3E%3Cpath d=\'M0.750003 30C0.750004 13.8457 13.8457 0.750007 30 0.750009C46.1543 0.75001 59.25 13.8457 59.25 30C59.25 46.1543 46.1543 59.25 30 59.25C13.8457 59.25 0.750001 46.1543 0.750003 30Z\' stroke=\'%23516072\' stroke-width=\'1.5\'/%3E%3C/svg%3E%0A');
              transform: rotate(0deg);
            }

            &.splide__arrow--next {
              background-image: url('data:image/svg+xml,%3Csvg width=\'60\' height=\'60\' viewBox=\'0 0 60 60\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M21.9713 29.0624C21.5287 29.505 21.5287 30.2226 21.9713 30.6652L29.1838 37.8776C29.6264 38.3202 30.344 38.3202 30.7866 37.8776C31.2292 37.4351 31.2292 36.7175 30.7866 36.2749L24.3755 29.8638L30.7866 23.4527C31.2292 23.0101 31.2292 22.2925 30.7866 21.8499C30.344 21.4073 29.6264 21.4073 29.1838 21.8499L21.9713 29.0624ZM37.2273 28.7304L22.7727 28.7304L22.7727 30.9971L37.2273 30.9971L37.2273 28.7304Z\' fill=\'%23516072\'/%3E%3Cpath d=\'M0.750003 30C0.750004 13.8457 13.8457 0.750007 30 0.750009C46.1543 0.75001 59.25 13.8457 59.25 30C59.25 46.1543 46.1543 59.25 30 59.25C13.8457 59.25 0.750001 46.1543 0.750003 30Z\' stroke=\'%23516072\' stroke-width=\'1.5\'/%3E%3C/svg%3E%0A');
              transform: rotate(180deg);
            }
          }

          &--prev {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }

  .ts {
    &__title-sm {
      margin: 9px 0 28px;
      font-weight: 600;

      @include media-breakpoint-down(lg) {
        display: inline-block;
        width: calc(100% - 4.75rem);
      }
    }

    &__text {
      position: relative;
      margin-bottom: 0;
      padding-left: 4.0625rem;

      @include media-breakpoint-down(lg) {
        font-size: $font-size-base * 1.25;
        line-height: (28 / 20);
      }

      @include media-breakpoint-down(md) {
        padding-left: 60px;
      }

      &::before {
        position: absolute;
        top: 11px;
        left: 0;
        display: inline-block;
        width: 40px;
        height: 30px;
        background-image: url('data:image/svg+xml,%3Csvg width=\'40\' height=\'30\' viewBox=\'0 0 40 30\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M31.7139 17.169C30.5216 20.7943 28.3159 23.2383 25.0969 24.501L28.0775 30C36.0258 25.8859 40 18.9002 40 9.04277L40 8.61507C40 6.17108 39.2052 4.11405 37.6155 2.44399C36.0258 0.814664 33.8003 -5.41995e-07 30.9389 -7.92147e-07C28.2365 -1.0284e-06 26.0904 0.814664 24.5007 2.44399C22.9111 4.11405 22.1162 6.17108 22.1162 8.61507C22.1162 11.0591 22.9111 13.0957 24.5007 14.7251C26.0904 16.3544 28.2762 17.169 31.0581 17.169L31.7139 17.169ZM9.59762 17.169C8.40537 20.7943 6.1997 23.2383 2.98063 24.501L5.96125 30C13.9096 25.8859 17.8838 18.9002 17.8838 9.04277L17.8838 8.61507C17.8838 6.17108 17.0889 4.11405 15.4993 2.44399C13.9096 0.814663 11.6841 -2.47546e-06 8.82266 -2.72561e-06C6.12022 -2.96186e-06 3.97417 0.814662 2.3845 2.44399C0.794833 4.11405 2.08319e-06 6.17108 1.86953e-06 8.61507C1.65587e-06 11.0591 0.794832 13.0957 2.3845 14.725C3.97417 16.3544 6.15996 17.169 8.94188 17.169L9.59762 17.169Z\' fill=\'%23573995\'/%3E%3C/svg%3E%0A');
        background-size: contain;
        background-repeat: no-repeat;
        content: '';
      }

      //&-inner {
      //  position: relative;
      //  display: inline-block;
      //
      //  &::after {
      //    content: '';
      //    position: absolute;
      //    right: 0;
      //    bottom: -30px;
      //    width: 40px;
      //    height: 30px;
      //    display: inline-block;
      //    background-image: url('data:image/svg+xml,%3Csvg width=\'40\' height=\'30\' viewBox=\'0 0 40 30\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M31.7139 17.169C30.5216 20.7943 28.3159 23.2383 25.0969 24.501L28.0775 30C36.0258 25.8859 40 18.9002 40 9.04277L40 8.61507C40 6.17108 39.2052 4.11405 37.6155 2.44399C36.0258 0.814664 33.8003 -5.41995e-07 30.9389 -7.92147e-07C28.2365 -1.0284e-06 26.0904 0.814664 24.5007 2.44399C22.9111 4.11405 22.1162 6.17108 22.1162 8.61507C22.1162 11.0591 22.9111 13.0957 24.5007 14.7251C26.0904 16.3544 28.2762 17.169 31.0581 17.169L31.7139 17.169ZM9.59762 17.169C8.40537 20.7943 6.1997 23.2383 2.98063 24.501L5.96125 30C13.9096 25.8859 17.8838 18.9002 17.8838 9.04277L17.8838 8.61507C17.8838 6.17108 17.0889 4.11405 15.4993 2.44399C13.9096 0.814663 11.6841 -2.47546e-06 8.82266 -2.72561e-06C6.12022 -2.96186e-06 3.97417 0.814662 2.3845 2.44399C0.794833 4.11405 2.08319e-06 6.17108 1.86953e-06 8.61507C1.65587e-06 11.0591 0.794832 13.0957 2.3845 14.725C3.97417 16.3544 6.15996 17.169 8.94188 17.169L9.59762 17.169Z\' fill=\'%23573995\'/%3E%3C/svg%3E%0A');
      //    background-repeat: no-repeat;
      //    background-size: contain;
      //  }
      //}
    }

    &-author {
      padding-left: 4.0625rem;

      @include media-breakpoint-down(md) {
        padding-left: 60px;
      }

      @include media-breakpoint-down(sm) {
        justify-content: center;
        padding-left: 0;
      }

      &__img {
        &-wrapper {
          width: 56px;
          height: 56px;
          background-color: $white;
        }
      }

      &__position {
        color: $gray-600;
        font-size: $font-size-sm;
      }
    }
  }
}

.section--platform-experts + .section--testimonials {
  padding-top: 0;

  .testimonials-slider:only-child {
    margin-top: 0;
  }
}

.testimonials-slider.testimonials-slider--short {
  .ts__text {
    font-size: 1.125rem;
    line-height: (24 / 18);

    @include media-breakpoint-down(md) {
      padding-top: 2rem;
      padding-left: 0;

      &::before {
        top: 0;
        width: 1.5rem;
        height: 1.125rem;
        font-size: 1rem;
      }
    }
  }

  .ts-author {
    margin-top: 1.5rem;

    @include media-breakpoint-down(md) {
      justify-content: flex-start !important;
      margin-top: 0.5rem;
    }
  }

  .ts-author__position {
    color: inherit;
  }
}

@include color-mode(dark) {
  .section--testimonials {
    .testimonials-slider {
      .splide {
        &__pagination-bar {
          background-color: $white;
        }

        &__arrow {
          background-image: url('data:image/svg+xml,%3Csvg width=\'32\' height=\'32\' viewBox=\'0 0 32 32\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cpath d=\'M20.2788 16.497C20.5131 16.2627 20.5131 15.8828 20.2788 15.6485L16.4604 11.8301C16.2261 11.5958 15.8462 11.5958 15.6119 11.8301C15.3776 12.0644 15.3776 12.4443 15.6119 12.6786L19.006 16.0728L15.6119 19.4669C15.3776 19.7012 15.3776 20.0811 15.6119 20.3154C15.8462 20.5497 16.2261 20.5497 16.4604 20.3154L20.2788 16.497ZM12.1454 16.6728L19.8545 16.6728L19.8545 15.4728L12.1454 15.4728L12.1454 16.6728Z\' fill=\'white\'/%3E%3Cpath d=\'M31.5 16C31.5 24.5604 24.5604 31.5 16 31.5C7.43959 31.5 0.5 24.5604 0.5 16C0.5 7.43959 7.43959 0.5 16 0.5C24.5604 0.5 31.5 7.43959 31.5 16Z\' stroke=\'white\'/%3E%3C/svg%3E%0A');
        }
      }

      .ts-author__position {
        color: #516072;
      }
    }
  }
}

.section--testimonials + .section--customers-slider {
  .customers-slider__title {
    padding-top: 0;
  }
}
